.prompt-form-container {
  width: 100%;
  left: 0;
  padding: 1.5rem;

  position: fixed;
  bottom: 0;

  .prompt-form {
    display: flex;
    align-items: end;
    justify-content: center;

    .input-wrapper {
      position: relative;
      display: flex;
      align-items: end;
      border-radius: 5px;
      box-shadow: 0 0 10px #0005;
      // background-color: #333;
      width: 100%;
      align-self: center;
      margin-bottom: 2vh;
      color: #fff;
    }

    .input {
      font-size: 16px;
      padding: 0.5rem;
      font-family: inherit;
      display: block;
      flex: 1;
      border: none;
      border-radius: 0.5rem 0 0 0.5rem;
      box-shadow: none; /* Prevents unexpected borders */
      resize: none;
      outline: none;
      padding-top: 1vh;
      // padding-bottom: 1vh;
      height: 2.5rem;
      background-color: #333;
      color: #fff;
      position: relative;
  
      /* Prevents rendering issues */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  
      /* Scrollbar styling */
      &::-webkit-scrollbar {
          width: 4px;
          background-color: rgba(255, 255, 255, 0.1);
      }
  
      /* Firefox scrollbar */
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }
  

    .input-btn.send-btn {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    .input-btn.upload-file {
      border-radius: 0.5rem 0 0 0.5rem;
      background: #333 !important;
      align-self: stretch !important;
      height: auto;
      display: flex;
      align-items: end;
      padding: 0.75rem 0;
    }
  }

  .input-btn {
    font-size: 1rem;
    padding: 0;
    border: none;
    margin: 0;
    border-radius: 0;
    position: relative;
    height: inherit;
    background-color: inherit;
    color: #aaa !important;
    background: hsla(234, 91%, 47%, 1) !important;

    background: linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;

    background: -moz-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;

    background: -webkit-linear-gradient(
      135deg,
      hsla(234, 91%, 47%, 1) 34%,
      hsla(282, 100%, 48%, 1) 100%
    ) !important;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
  }
  .uploadBtn {
    font-size: 1.3rem;
    padding: 0;
    border: none;
    margin: 0;
    border-radius: 0;
    padding-left: 2vw;
    padding-right: 1vw;
    height: 2.5rem;
    background-color: inherit !important;
    color: #aaa !important;
  }

  .word-helpers-wrapper {
    filter: drop-shadow(0 0 4px hsl(280, 95%, 75%));
    position: absolute;
    bottom: calc(100% + 15px);
    left: 50%;
    width: 100%;
  }

  .word-helpers {
    display: none;
    flex-wrap: wrap;
    overflow-x: auto;
    justify-content: center;
    margin-top: 0.5em;
    padding: 1em;
    max-width: 100%;
    background-color: white;
    transform: translateX(-50%);
  }

  .word-helpers button {
    font-family: inherit;
    padding: 0.5em 1em;
    margin: 0.3em;
    border: none;
    border-radius: 0.5em;
    background-color: hsl(280, 85%, 90%);
    align-items: center;
    box-shadow: 0 0 0 1px hsl(280, 95%, 75%);
    transition: background-color 0.3s ease;
    flex: 1 0 max-content;
  }

  .word-helpers button:hover {
    background-color: white;
    cursor: pointer;
  }
}

@media (max-width: 450px) {
  .prompt-form-container {
    padding: 0.5rem;
    .prompt-form {
      display: flex;
      align-items: end;
      justify-content: center;
      position: relative;
      .prompt-form-inner {
        width: 95.8% !important;
      }
      .input-wrapper {
        display: flex;
        align-items: end;
        border-radius: 50px;
        padding: 0.2rem;
      }
      .form-btn {
        display: none;
      }
      .input-btn {
        width: 2rem;
        height: inherit;
        font-size: 14px;
      }
      .mobile-form-btn {
        width: 11vw;
        font-size: 1rem;
        border: none;
        margin: 0;
        padding: 0;
        top: 2.9vh;
        left: 3vw;
        position: relative;
        border-radius: 50px;
        background: hsla(234, 91%, 47%, 1) !important;

        background: linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;

        background: -moz-linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;

        background: -webkit-linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;
      }
      .moreCaret {
        color: "white";
        font-family: var(--fa-style-family);
        font-weight: var(--fa-style, 900);
      }
      .input-btn.send-btn:disabled {
        border: none;
        margin: 0;
        padding: 0;
        border-radius: 50px;
        background: hsla(234, 91%, 47%, 1) !important;

        background: linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;

        background: -moz-linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;

        background: -webkit-linear-gradient(
          135deg,
          hsla(234, 91%, 47%, 1) 34%,
          hsla(282, 100%, 48%, 1) 100%
        ) !important;
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 450px) {
  .prompt-form-container .prompt-form {
    .input-wrapper {
      background-color: transparent;
      overflow: visible;
    }
    .input-btn.upload-file {
      background-color: #333 !important;
      border-radius: 1.5rem 0 0 1.5rem;
      align-self: stretch !important;
      height: auto !important;
      display: flex;
      align-items: end;
      font-size: 16px;
      padding: 0.75rem 0;
      margin-right: -1px;
    }
    .input-btn.send-btn {
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }
    .input {
      background-color: #333 !important;
      border-radius: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

@media (min-width: 800px) {
  .prompt-form-container {
    display: flex;
    justify-content: center;
    left: $sidebar-width;
    width: calc(100vw - $sidebar-width);
    .prompt-form {
      width: min(100%, 800px);
    }
  }
}
